<template>
  <el-tooltip placement="top">
    <span slot="content" style="white-space: pre-line" :style="'display: inline-block;' + 'width:'+ width">{{ content?content:tpContent }}</span>
    <img class="expanded_s" :style="'width:' + imgWidth" src="../../assets/img/robot/robot_strategy/explain.svg">
  </el-tooltip>
</template>

<script>
export default {
  name: "tooltip",
  data() {
    return{
      tpContent: '',
      tpWidth: '',
      imageWidth: '',
    }
  },
  props:{
    content: {type: String, required: true},
    width: {type: String,default:'auto'},
    imgWidth: {type: String, default: 'auto'}
  },
  watch:{
    content(val){
      this.tpContent = val
      console.log(val)
    },
    width(val){
      this.tpWidth = val
      console.log(val)
    },
    imgWidth(val){
      this.imageWidth = val
    },
  },
}
</script>

<style scoped>
.expanded_s{
  vertical-align: middle;
  width: 17px;
  margin-left: 10px
}

</style>
