import request from "../utils/request";

/**
 * 获取直推用户列表
 * @param pageNum
 * @returns {AxiosPromise}
 */
export const getRecommendList=(pageNum,pageSize)=>request({
    method:'GET',
    url:'/subSale/getRecommendUserListByPage',
    params:{pageNum,pageSize}
})

/**
 * 本月奖励记录
 * @param pageNum
 * @returns {AxiosPromise}
 */
export const getAwardList=(pageNum,pageSize)=>request({
    method:'GET',
    url:'/subSale/getCommissionRecordListByPage',
    params:{pageNum,pageSize}
})

/**
 *  本月资产记录
 * @param pageNum
 * @returns {AxiosPromise}
 */
export const getCapitalList=(pageNum,pageSize)=>request({
    method:'GET',
    url:'/subSale/getDepositRecordListByPage',
    params:{pageNum,pageSize}
})

/**
 * 获取用户信息
 * @returns {AxiosPromise}
 */
export const getUserInfo=()=>request({
        method:'GET',
        url:'/subSale/getUserInfo',
})

/**
 * 推广二维码
 * @returns {AxiosPromise}
 */
export const getQrCode=()=>request({
    method:'GET',
    url:'/subSale/getUserQrCode',
    responseType:'blob',

})
/**
 * 奖励余额转到保证金资产
 */
export const transferProperty=()=>request({
    method:'GET',
    url:'/subSale/operateTransferReward',
})

/**
 * 提现
 */
export const userWithdraw=(address,money,vCode)=>request({
    method:'post',
    url:'/wallet/applyWithdraw',
    params:{address,money,vCode,sendType:1}
})

/**
 * 提现New
 */
export const userWithdrawNew=(address,money,vCode)=>request({
    method:'post',
    url:'/wallet/applyWithdrawNew',
    params:{address,money,vCode,sendType:1}
})

/**
 * 設置上級
 */
export const setSuperior=(parentInvitation)=>request({
    method:'get',
    url:'/subSale/modifyParentInvitation',
    params:{parentInvitation}
})

/**
 * 修改备注名
 * @param invitation
 * @param tag
 * @returns {AxiosPromise}
 */
export const setRecommendUserTag = (invitation, tag) =>request({
    method: 'POST',
    url: '/subSale/setRecommendUserTag',
    params:{invitation, tag}
})
